import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import Seo from "../components/Seo"
import Logo from "../assets/seaconnectlogoWhite.svg"

const NotFoundPage = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const seo = {
    title: t("notFoundPage.seo.title"),
    description: t("notFoundPage.seo.description"),
    lang: language,
  }

  return (
    <main className="flex flex-col items-center justify-center w-full h-screen bg-azul bg-[url('../images/backpresentacion.png')] bg-[length:50px]">
      <Seo datos={seo} />
      <Logo className="w-50 h-32" />
      <h1 className="text-blanco text-3xl font-light mt-3 mb-10">
        {t("notFoundPage.error")}
      </h1>
      <Link
        to={t("rutas.home")}
        className="transition duration-200 rounded-full border bg-azul text-blanco border-blanco hover:bg-blanco hover:text-azul hover:border-blanco p-3 px-14"
      >
        {t("notFoundPage.boton")}
      </Link>
    </main>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
